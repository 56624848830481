import React, { useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from './components/Loading';
import './App.css';

const ShopsList = React.lazy(() => import('./components/ShopsList'));

function App() {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [shopsData, setShopsData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadShops() {
      try {
        const response = await fetch('/data/shops.json'); // Ensure this path is correct
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        // Check if data has the expected structure
        if (!data.en || !Array.isArray(data.en)) {
          throw new Error('Invalid data format: Missing "en" array.');
        }

        // Cleanup shopsData: remove whitespace from keys
        data.en.forEach((shop) => {
          Object.keys(shop).forEach((key) => {
            const newKey = key.trim();
            if (newKey !== key) {
              shop[newKey] = shop[key];
              delete shop[key];
            }
          });
        });

        // Repeat cleanup for Arabic data if exists
        if (data.ar && Array.isArray(data.ar)) {
          data.ar.forEach((shop) => {
            Object.keys(shop).forEach((key) => {
              const newKey = key.trim();
              if (newKey !== key) {
                shop[newKey] = shop[key];
                delete shop[key];
              }
            });
          });
        }

        setShopsData(data);
        setLoading(false);
      } catch (err) {
        console.error('Error loading shops data:', err);
        setError(err.message);
        setLoading(false);
      }
    }

    loadShops();
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute(
      'dir',
      i18n.language === 'ar' ? 'rtl' : 'ltr'
    );
  }, [i18n.language]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className='flex items-center justify-center min-h-screen'>
        <div className='text-red-500'>
          <p>Failed to load shops data: {error}</p>
        </div>
      </div>
    );
  }

  const currentShopsData = shopsData[i18n.language] || [];

  return (
    <div className='flex items-center justify-center min-h-screen'>
      <div className='container w-full max-w-6xl px-4'>
        {/* <div className='flex justify-center'>
          <img
            src='/unhcr-logo2.png'
            alt='UNHCR Logo'
            className='pt-2 mb-2 w-1/3'
          />
        </div> */}
        <div className='flex justify-between mb-9'>
          <img src='/omt_logo.svg' alt='OMT Logo' className='pt-2 w-1/5' />
          <img
            src='/unhcr-logo-o.png'
            alt='UNHCR Logo'
            className='pt-2 w-1/3'
          />
          <img src='/blom-logo-o.png' alt='BLOM Logo' className='pt-2 w-1/5' />
        </div>

        <Suspense fallback={<Loading />}>
          <ShopsList shopsData={currentShopsData} />
        </Suspense>
      </div>
    </div>
  );
}

export default App;
