import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Type to search...': 'Type to search...',
      District: 'District',
      'Select District': 'Select District',
      City: 'City',
      'Select City': 'Select City',
      'Opening Hours': 'Opening Hours',
      'Monday to Friday': 'Monday to Friday:',
      Saturday: 'Saturday:',
      Sunday: 'Sunday:',
      // ...other translations...
      English: 'English',
      Arabic: 'Arabic',
    },
  },
  ar: {
    translation: {
      'Type to search...': 'اكتب للبحث...',
      District: 'المنطقة',
      'Select District': 'اختر المنطقة',
      City: 'المدينة',
      'Select City': 'اختر المدينة',
      'Opening Hours': 'ساعات العمل',
      'Monday to Friday': 'من الاثنين إلى الجمعة:',
      Saturday: 'السبت:',
      Sunday: 'الأحد:',
      // ...other translations...
      English: 'الإنجليزية',
      Arabic: 'العربية',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // default language
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
